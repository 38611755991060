// 西班牙
export default {
  loginPage:{
    mobileNumber:'Número de teléfono móvil',
    password:'Contrase?a',
    forgotPassword:'?Ha olvidado su contrase?a?',
    goToRegister:'Ir a Registro',
    rememberPassword:'Recuerde su contrase?a',
    login:'Inicio de sesión',
    noAccountYet:'Todavía no hay cuenta?',
    mobileNumberPlaceholder:'Por favor, introduzca su número de teléfono móvil',
    mobileNumberMessage:'Por favor, introduzca su número de teléfono móvil',
    passwordPlaceholder:'Introduzca una contraseña',
    passwordMessage:'Introduzca una contraseña',
    loginSuccessful:'Вход в систему выполнен успешно!',
    loginFailed:'Не удалось войти!'
  },
  //   注册页
  registerPage:{
    register:'  Registro ',
    mobileNumber:'Número de teléfono móvil',
    mobileNumberPlaceholder :'Introduzca su número de teléfono móvil',
    mobileNumberMessage:'Introduzca su número de teléfono móvil',
    loginPassword:'Contrase?a de inicio de sesión',
    loginPasswordPlaceholder:'Por favor, introduzca su contrase?a de acceso',
    loginPasswordMessage:'Por favor, introduzca su contrase?a de acceso',
    invitationCode:'Código de invitación',
    invitationCodePlaceholder:'Introduzca el código de invitación',
    invitationCodeMessage:'Introduzca el código de invitación',
    fundingPassword:'Código del dinero',
    fundingPasswordPlaceholder:'Introduzca su código de financiación',
    fundingPasswordMessage:'Introduzca su código de financiación',
    iKnowAndAgree:'Soy consciente y acepto',
    accountOpeningAgreement:'"Acuerdo de apertura de cuenta"',
    theTreaties:'Tratados',
    signUpNow:'Regístrese ahora',
    pleaseAgree:'Por favor, acepte primero el acuerdo de registro',
    existingAccount:'Ya tiene una cuenta',
    loginNow:'"Acceder ahora"',
    registrationFailed:'Error de registro',
    registrationSuccessful:'Registro exitoso',
    iKnow:'Lo tengo.',
    accountOpen:'Acuerdo de apertura de cuenta'
  },
  // 主页页面
  indexPage:{
    indexList:'Error al obtener los datos de la página de inicio',
    amazonLetMiaogouOpenYourWayToWealth:'Amazon Deja que Miaosho abra tu camino hacia la riqueza',
    myAssets:'Mis activos($)',
    recharge:'recargar',
    withdrawal:'Retiros',
    yesterdaysIncome:'Ganancias de ayer($)',
    cumulativeIncome:'Ganancias acumuladas($)',
    todaysIncome:'Ganancias de hoy($)',
    freezeFunds:'Congelación de fondos($)',
    regularFinancialManagement:'Banca regular',
    quickRecharge:'Recarga rápida',
    quickWithdrawal:'Pagos rápidos',
    inviteFriends:'Invitar a amigos',
    taskHall:'Lobby de la misión',
    unlocked:'Desbloqueado',
	service:'Servicio al cliente',
	leaderboard:'Retiro de efectivo'
  },
  // list页面
  listPage:{
    secondPurchaseRecord:'Registro de segundos de compra',
    this:'Estos datos son proporcionados por los principales funcionarios de cooperación.',
    Remaining:'Activos disponibles restantes($)',
    allOrders:'Todos solteros',
    toBeProcessed:'Pendiente',
    completed:'Completado',
    freezing:'Congelado',
    totalAmountOfTheOrder:'El saldo total del pedido',
    commission:'comisión',
    Estimated:'Descuentos estimados',
    submitOrder:'Enviar el pedido',
    secondpurchasetime:'Segundos para ganar tiempo:',
    secondPurchaseNumber:'Número de compra de segundos:',
    NotSubmitted:'No enviar en este momento',
    submitNow:'Enviar ahora'
  },
  // order页面
  orderPage:{
    grabAnOrder:'Toma órdenes sin parar',
    hiShop:'Hola tienda',
    winTheFutureWithYou:'Ganar-ganar futuro contigo',
    orderText:'Fundada en 1994&nbsp;, Amazon Marketplace trabaja con más de 1 millón de empresas de comercio electrónico y procesa entre 150.000 y 30 millones de pedidos por día. Dado que la gestión del fondo se confía a la empresa de gestión de activos, puede evitar las solicitudes de reembolso de los usuarios y la fuga de capitales de las empresas de comercio electrónico, y los clientes inteligentes en la nube y las IP de los usuarios se utilizan de acuerdo con las reglas establecidas. El concurso automático de pedidos evitará que los comerciantes sean prohibidos, degradados o prohibidos debido a los errores de los usuarios que toman pedidos. Como intermediario, continuaremos esforzándonos por aumentar la confianza y la eficiencia de los comerciantes y usuarios cuando compitan por pedidos.',
    bronzeMember:'Miembro Bronce',
    commission:'Comisión ({rate}%)',
    Matching:'Pedidos coincidentes para usted, espere un momento',
    dispatch:'Envío',
    grabAnOrder:'Agarrar pedidos',
    balance:'equilibrar',
    recharge:'recargar',
    withdrawal:'Retiros',
    matchingRules:'Hacer coincidir la regla',
    commissionEarnedToday:'Comisión ganada hoy',
    Today:'El número de probabilidades se ha agarrado hoy',
    oddNumber:'Número singular que queda hoy',
    yesterdays:'La comisión de ayer',
    User:'usuario',
    getCommission:'Recibe comisiones',
	note: 'Nota: La cantidad del pedido y la comisión aumentarán a medida que aumente el nivel VIP'
  },
  // my页面
  myPage:{
    logout:'Cerrar sesión',
    areYouSureYouWantToLogOut:'¿Está seguro de cerrar sesión?',
    confirnText:'Estás seguro',
    cancleText:'Cancelar',
    amazonReputation:'Amazon Credibilidad:👑',
    accountBalance:'Saldo de la cuenta',
    recharge:'recargar',
    withdrawal:'Retiros',
    deliveryAddress:'Dirección de envío',
    iWantToPromote:'Quiero promocionar',
    secondPurchaseRecord:'Registro de segundos de compra',
    accountDetails:'Detalles de la cuenta',
    teamReport:'Informes de equipo',
    companyQualification:'Cualificaciones de la empresa',
	certificate: 'certificado'
  },
  accountDetailsPage:{
    accountDetails:'Detalles de la cuenta',
    startDate:'Fecha de inicio',
    endDate:'Fecha final',
    search:'Buscar',
    alltypes:'Todos los tipos',
    withdrawalRecord:'Historial de retiros',
    rechargeRecord:'Registros de recarga',
    withDrawal:'Retiros',
    UAddress:'Dirección U',
    rebateForOrderGrabbing:'Descuento por agarrar pedidos',
    orderGrab:'Agarrar pedidos',
    recharge:'recargar',
    tryToLoad:'Luchando por cargar',
    noMore:'Ya no más',
    cancel:'Cancelar',
    ok:'Estás seguro',
    pleaseSelectATime:'Por favor, seleccione una hora'
},
teamPage:{
    teamReport:'Informes de equipo',
    startDate:'Fecha de inicio',
    endDate:'Fecha final',
    search:'Buscar',
    teamBalance:'Equilibrio del equipo($)',
    teamFlow:'Flujo de equipo($)',
    totalTeamRecharge:'Recarga total del equipo($)',
    theTotalWithdrawalOfTheTeam:'Total de retiros de equipos($)',
    teamOrderCommission:'Comisión por pedidos de equipo($)',
    myCommission:'Mi comisión($)',
    directPushNumber:'Jefes de referencia directa',
    teamSize:'Número de miembros del equipo',
    todaysNewNumberOfPeople: 'El nuevo número de hoy',
    todaysActiveNumber:'Número de personas activas en la actualidad',
    level1:'Nivel',
    level2:'Nivel 2',
    level3:'Nivel tres',
    tryToLoad:'Luchando por cargar',
    noMore:'Ya no más',
    mobilePhone:'Número de teléfono móvil',
    Number:'Número de promotores',
    withdrawals:'Retiros',
    recharge:'recargar',
    time:'Tiempo de registro'
},
culturePage:{
    title:'Cualificaciones de la empresa'
},
addressPage:{
  address:'dirección',
  realName:'Nombre real',
  pleaseEnterYourRealName:'Por favor, introduzca su nombre real',
  mobileNumber:'Número de teléfono móvil',
  pleaseEnterThePhoneNumber:'Por favor, introduzca su número de teléfono móvil',
  region:'región',
  pleaseEnterTheRegion:'Introduce una región',
  detailedAddress:'Dirección completa',
  pleaseEnterDetailedAddress:'Por favor, introduzca su dirección completa',
  submit:'Enviar'
},
// Popularize页面
popularizePage:{
  iWantToPromote:'Quiero promocionar',
  inviteNewUsers:'Invitar a nuevos usuarios',
  winTheFutureWithYou:'Ganar-ganar futuro contigo',
  screenshot:'Tome una captura de pantalla para guardar el código QR',
  friend:'Identificación y registro de amigos',
  complete:'Complete la prisa de registro',
  distribute:'Los sobres rojos en efectivo se distribuirán dentro de las 24 horas',
  invitationCode:'Código de invitación:'
},
// 充值页面
rechargePage:{
  recharge:'recargar',
  currentBalance:'Saldo corriente($)',
  rechargeApplication:'Aplicación de recarga en curso($)',
  accumulatedRecharged:'Recarga acumulada($)',
  rechargeAmount:'Importe adicional',
  pleaseEnterTheRechargeAmount:'Por favor, introduzca la cantidad de recarga',
  rechargeNow:'Recarga ahora'
},
// 提现页面
withDrawalPage:{
  withDrawal:'Retiros',
  currentBalance:'El importe actual($)',
  withdrawalApplicationInProgress:'Solicitud de retiro($)',
  cumulativelyWithdrawn:'Retiros acumulados($)',
  withdrawalAmount:'Monto del retiro',
  pleaseEnterTheWithdrawalAmount:'Por favor, introduzca el importe de la retirada',
  withdrawAll:'Retirar todo',
  uAddress:'Dirección U',
  pleaseEnterUAddress:'Por favor, introduzca la dirección U',
  transactionPassword:'Contraseñas comerciales',
  pleaseEnterTheTransactionPassword:'Introduzca la contraseña de la transacción',
  withdrawNow:'Retirar inmediatamente',
  allFieldsCannotBeEmpty:'Todos los campos no pueden estar vacíos',
  able:'Cantidad de efectivo retirable'
},
// 抢单列表页面
grabListPage:{
  failedToGetTheOrderGrabList:'Error al obtener la lista de agarre de pedidos'
},
	vipPage:{
		'vip_level': 'Nivel VIP',
		'current_level': 'Nivel actual',
		'vip_text1': '{order_num} se pueden obtener pedidos por día',
		'vip_text2': '{rate}% de beneficio por pedido',
		'vip_text3': 'Se activa un solo depósito {recharge} USDT',
	}
}
<template>
   <div>
     <div class="home-contrain">
        <!-- 头部 -->
        <div class="header">
            <!-- 左边 -->
            <div class="header-left">
                <H1>Hello</H1>
                <span>{{ $t('indexPage.amazonLetMiaogouOpenYourWayToWealth') }}</span>
            </div>
            <!-- 右边 -->
            <div class="header-right">
                <img src="../assets/image/头像.png" alt="">
            </div>

            <!-- 三个小圆圈 -->
            <div class="circle1"></div>
            <div class="circle2"></div>
            <div class="circle3"></div>
        </div>
        <!-- 半圆弧 -->
        <div class="half-circle">

        </div>
        <!-- 中间主体 -->
        <div class="content-contrain">
            <div class="home-card">
                <div class="home-card-top">
                    <div class="top-left">
                        <div class="left-text">{{ $t('indexPage.myAssets') }}</div>
                        <div class="left-money">${{ indexList.balance }}</div>
                    </div>
                    <div class="top-right">
                       <div>
                        <van-button type="danger" round size="small" to="/recharge">{{ $t('indexPage.recharge') }}</van-button>
                       </div>
                        <div>
                            <van-button type="primary" round size="small" to="/withdrawal">{{ $t('indexPage.withdrawal') }}</van-button>
                        </div>
                    </div>
                </div>
               <div class="home-card-bottom">
                <div class="bottom-box">
                  <span class="box_money">{{ indexList.yesterdayincome }}</span>
                  <span class="box-text">{{ $t('indexPage.yesterdaysIncome') }}</span>
                </div>
                <div class="bottom-box">
                  <span class="box_money">{{ indexList.totalincome }}</span>
                  <span class="box-text">{{ $t('indexPage.cumulativeIncome') }}</span>
                </div>
                <div class="bottom-box">
                  <span class="box_money">{{ indexList.todayincome }}</span>
                  <span class="box-text">{{ $t('indexPage.todaysIncome') }}</span>
                </div>
                <div class="bottom-box">
                  <span class="box_money">{{ indexList.frozen }}</span>
                  <span class="box-text">{{ $t('indexPage.freezeFunds') }}</span>
                </div>
               </div>
            </div>
        </div>
        <!-- 四块按钮 -->
        <div class="tabs-contrain">
           <router-link to="/recharge" class="tab-item">
            <div class="item-img">
              <img src="@/assets/image/chongzhi.png" alt="">
            </div>
            <span class="tab-item-text">{{ $t('indexPage.recharge') }}</span>
           </router-link>
			   
           <router-link to="/withdrawal" class="tab-item">
            <div class="item-img">
              <img src="@/assets/image/tixian.png" alt="">
            </div>
            <span class="tab-item-text">{{ $t('indexPage.withdrawal') }}</span>
           </router-link>
		   
           <router-link to="/popularize" class="tab-item">
            <div class="item-img">
              <img src="@/assets/image/yaoqing.png" alt="">
            </div>
            <span class="tab-item-text">{{ $t('indexPage.inviteFriends') }}</span>
           </router-link>
		   
           <router-link to="/home/vip" class="tab-item">
            <div class="item-img">
              <img src="@/assets/image/kefu.png" alt="">
            </div>
            <!-- <span class="tab-item-text">{{ $t('indexPage.service') }}</span> -->
			<span class="tab-item-text">VIP</span>
           </router-link>
        </div>
        <!-- 任务大厅 -->
        <div class="floor-contrain">
          <h3>{{ $t('indexPage.taskHall') }}</h3>
          <div class="floor-card" @click="goToOrder">
            <div class="card-left">
              <span>Amazon</span>
              <van-button round size="small" to="order">{{ $t('indexPage.unlocked') }}</van-button>
            </div>
            <div class="card-right">
            </div>
          </div>
        </div>
		<!-- 排行榜 -->
		<div class="topRec_List floor-contrain">
			<h3>{{ $t('indexPage.leaderboard') }}</h3>
		        <div class="maquee">
		            <ul>
		                <li v-for="item in tixianList" :key="item.id">
		                    <div>{{item.mobile}}</div>
		                    <div>{{item.usdt}}</div>
		                    <div class="ti-money">${{item.money}}</div>
		                </li>
		            </ul>
		        </div>
		    </div>
		
     </div>
   </div>
</template>

<script>
import { Toast } from 'vant';
import { getApi } from '@/api/api.js'
import { mapState } from 'vuex'
import $ from 'jquery'
  export default {
    computed:{
      ...mapState({indexList:'indexList'})
    },
      created() {
        this.getIndexList();
		
		this.getindexnotice();
      },
	  mounted() {
	  	var obj=$('.maquee');
	  	setInterval(function(){
	  		$(obj).find("ul").animate({
	  			marginTop : "-39px"  
	  		},500,function(){  
	  			$(this).css({marginTop : "0px"}).find("li:first").appendTo(this);  
	  		}) 
	  	},2000);
	  },
      data() {
        return {
          // indexList:{}
		  tixianList:[],
        }
      },
      methods:{
       async getIndexList() {
          const res = await getApi('/api/index/index')
          if(res.code !== 1) return Toast(this.$i18n.t('indexPage.indexList'))
          this.$store.commit('setListData',res.data)
		  this.tixianList=res.data.tixian;
        },		
        goToOrder() {
          this.$router.push({name:'order'})
        },
		
		//获取公告
		async getindexnotice() {
			const res = await getApi('/api/user/indexnotice')
			if(res.code === 1 && res.data != null && res.data.content != '') {
			   this.$dialog.alert({
			     message: res.data.content,
			     confirmButtonText: this.$i18n.t('registerPage.iKnow'),
			     confirmButtonColor:'#658af7'
			   });
			}
		 }
      }
  }
</script>

<style lang="scss" scoped>
a {
  color:#000;
}
.home-contrain {
  margin-bottom: 45px;
}
  /*头部*/
  .header {
    width: 100%;
    height: 110px;
    background-color: #d4e3fa;
    position: relative;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 20px;
  }
  .header-left {
    span {
       font-size: 12px;
    }
  }
  .header-right {
    img {
        width: 50px;
    }
  }

  .circle1 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f1f5fe;
    position: absolute;
    top: 80px;
    left: 20px;
  }
  .circle2 {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #f1f5fe;
    position: absolute;
    top: 10px;
    left: 200px;
  }
  .circle3 {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #f1f5fe;
    position: absolute;
    top: 26px;
    left: 335px;
  }
  .half-circle {
    width: 100%;
    height: 160px;
    background-color: #dbeafd;
    border-radius: 0 0 100% 100%;
  }

  /*主体*/
  .content-contrain {
    width: 100%;
    padding: 15px;
    margin-top: -160px;

    .home-card {
        width: 100%;
        height: 214px;
        position: relative;

        .home-card-top {
            width: 100%;
            height: 120px;
            background-color: #5393f5;
            border-radius: 20px 20px 0 0;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: space-around;

            .top-left {
                color: #fff;

                .left-text {
                    font-size: 12px;
                }
                
                .left-money {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: 700;
                }

            }
            .top-right {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .van-button {
                    width: 100px;
                    margin-top: 5px;
                }
            }
        }
        .home-card-bottom {
            width: 100%;
            height: 95px;
            background-color: #6ca6f7;
            overflow: hidden;
            position: absolute;
            bottom: 0;
            border-radius: 0 0 10px 10px;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #fff;

            .bottom-box {
              width: 25%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .box_money {
                font-size: 18px;
              }
              .box-text {
                text-align: center;
              }
            }
        }
    }
  }

  /*四个按钮*/
  .tabs-contrain {
    display: flex;
    justify-content: space-between;
    padding: 23px;
    .tab-item {
      display: flex;
      width: 25%;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
      
      .item-img {
        width: 40px;
        height: 40px;
        //background-color: blue;
        border-radius: 10px;
        margin-bottom: 3px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .tab-item-text {
        text-align: center;
      }
    }
  }

  .tabs-contrain a:nth-of-type(1) .item-img{
    //background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);
  }
  .tabs-contrain a:nth-of-type(2) .item-img{
    //background-image: linear-gradient(to top, #e8198b 0%, #c7eafd 100%);
  }
  .tabs-contrain a:nth-of-type(3) .item-img{
    //background-image: linear-gradient(to top, #fddb92 0%, #d1fdff 100%);
  }
  .tabs-contrain a:nth-of-type(4) .item-img{
    //background-image: linear-gradient(to top, #feada6 0%, #f5efef 100%);
  }

  /*任务大厅*/
  .floor-contrain {
    padding: 15px;
    .floor-card {
      margin-top: 25px;
      background-color: #5393f4;
      height: 100px;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      

      .card-left {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        color: #fff;
        font-weight: 700;
        .van-button {
          width: 70px;
        }
      }
      .card-right {
        border-radius: 10px;
         width: 80px;
         height: 80px;
         background: url(@/assets/image/amazon.png);
         background-size: 100% 100%;
      }
    }

  }



.maquee{ width:90%;height:195px; overflow:hidden; margin:20px auto; color:#7C7C7C}
.topRec_List ul{ width:100%; height:195px;}
.topRec_List li{ width:100%; height:38px; line-height:38px; font-size:12px; border-bottom: 1px dashed #aaa;}
.topRec_List li div{ float:left;}
.topRec_List li div:nth-child(1){ width:30%;}
.topRec_List li div:nth-child(2){ width:30%;}
.topRec_List li div:nth-child(3){ width:40%;}

.ti-money{color:#ff9b35; font-weight: bold; text-align: right;}

</style>